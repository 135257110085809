.paragraphW {
  color: white;
  font-size: 22px;
  font-style: normal;
  font-weight: 350;
  line-height: 30px; /* 136.364% */
  margin-top: 30px;

  @media (max-width: 650px) {
    font-size: 12px;
    line-height: 18px; /* 150% */
    margin-top: 15px;
  }
}
