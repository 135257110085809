.titleTab {
  color: #003a74;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.36px;
  text-transform: uppercase;

  @media (max-width: 780px) {
    font-size: 28px;
  }
}
