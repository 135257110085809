.card {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align-last: left;
  width: 262px;
  height: 311px;
  background: white;
  padding: 20px;
  border-radius: 7px;
  background: #fefefe;
  box-shadow: 0px 4px 30px 0px rgba(0, 65, 92, 0.35);

  &:hover {
    .circle {
      background: #00a1e4;
    }
  }

  .imgSer {
    width: 66px;
    height: 66px;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 35px;
  }

  .title {
    position: relative;
    color: #003a74;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    text-transform: capitalize;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: #003a74;
      width: 222px;
      height: 1px;
      bottom: -3px;
      left: 0;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 15px 7px;
    li {
      position: relative;
      color: #003a74;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 136.5%; /* 17.745px */

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        left: -10px;
        bottom: 7px;
        border-radius: 50%;
        background: #003a74;
      }
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: #003a74;
    border-radius: 50%;
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;

    .arrowRight {
      color: white;
      width: 30px;
      height: 30px;
    }
  }
}
