.wrap {
  text-align-last: center;

  .subheading {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.44px;
    text-transform: uppercase;
    margin-top: 30px;

    @media (max-width: 650px) {
      margin-top: 20px;
      font-size: 24px;
      letter-spacing: -0.96px;
    }
  }
}

.wrap + .wrap {
  padding: 50px 0 0 0;

  @media (max-width: 650px) {
    padding: 30px 0 0 0;
  }
}

.textWrap {
  margin-top: 40px;
  text-align-last: left;

  @media (max-width: 650px) {
    margin-top: 15px;
  }
}
