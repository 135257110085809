.tabCard {
  position: relative;
  display: flex;
  column-gap: 55px;
  align-items: center;
  z-index: 2;

  cursor: pointer;
  max-width: 554px;
  min-height: 165px;
  max-height: 164px;
  padding: 40px 60px;
  border-radius: 7px;

  color: #003a74;
  background: linear-gradient(180deg, #fefefe -0.77%, #fefefe 100%);
  box-shadow: 0px 4px 30px 0px rgba(0, 149, 211, 0.35);

  @media (max-width: 650px) {
    column-gap: 20px;
    max-height: 178px;
    padding: 21px 18px;
  }

  // если класс актив
  &_active {
    position: relative;
    display: flex;
    column-gap: 55px;
    align-items: center;
    z-index: 2;

    cursor: pointer;
    max-width: 554px;
    min-height: 165px;
    max-height: 164px;
    padding: 40px 60px;
    border-radius: 7px;

    color: white;
    background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);
    box-shadow: 0px 2px 20px 0px rgba(0, 114, 161, 0.07);

    .cardTitle {
      border-bottom: solid 1px #fff;
    }

    .cardImg {
      filter: brightness(0) invert(1);
    }

    .circle {
      transform: rotate(90deg);
      background: #003a74;
    }

    @media (max-width: 650px) {
      column-gap: 20px;
      max-height: 178px;
      padding: 21px 18px;
    }
  }

  //Наведение на елемент
  &:hover {
    color: white;
    background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);

    .cardTitle {
      border-bottom: solid 1px #fff;
    }

    .cardImg {
      filter: brightness(0) invert(1);
    }

    .circle {
      transform: rotate(90deg);
      background: #003a74;
    }
  }
}

.rightWrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .cardTitle {
    position: relative;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.88px;
    text-transform: capitalize;
    border-bottom: solid 1px #00a1e4;
  }

  .cardText {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.5%; /* 17.745px */
  }
}

.circle {
  transition: all 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 23px;
  bottom: -26px;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background: #00a1e4;

  .arrowRight {
    color: white;
    width: 30px;
    height: 30px;
  }
}

.cardImg {
  position: relative;
}

.corner {
  position: absolute;
  height: 100%;
  right: 0;
  bottom: -2px;
}
