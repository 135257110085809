.wrap {
  text-align-last: center;
}

.wrap + .wrap {
  padding: 50px 0 0 0;
  @media (max-width: 650px) {
    padding: 15px 0 0 0;
  }
}

.textWrap {
  margin-top: 40px;
  text-align-last: left;
  @media (max-width: 650px) {
     margin-top: 15px;
  }
}
//подзаголовки

.prevHeader {
  color: #003a74;
  font-size: 24px;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.96px;
  text-transform: uppercase;

  @media (max-width: 650px) {
    font-size: 24px;
    letter-spacing: -0.64px;
  }
}

.prevHead {
  color: #003a74;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  @media (max-width: 650px) {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
}

//Белый

.headListW {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */

  @media (max-width: 650px) {
    font-size: 16px;
  }
}

.listW {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  @media (max-width: 650px) {
    row-gap: 0;
  }
  .numW {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    margin-right: 15px;
  }

  li {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 26.28px */

    @media (max-width: 650px) {
      font-size: 14px;
      line-height: 18px; /* 150% */
      margin-top: 15px;
    }
  }
}

.imprintTopTop {
    color: #003a74;
    font-size: 22px;
    font-style: normal ;
    font-weight: bold;
    line-height: 30px; /* 136.364% */
    margin-top: 130px;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
    }
}

.imprintTopTop2 {
    color: #003a74;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: 30px; /* 136.364% */
    margin-top: 50px;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
        margin-bottom: 5px;
    }
}

.imprintTop {
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 30px; /* 136.364% */
    margin-top: 30px;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
    }
}

.imprint {
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px; /* 136.364% */
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: justify;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
    }
}

.imprintT {
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px; /* 136.364% */
    margin-top: 20px;
    margin-bottom: 0px;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
    }
}

.imprintBold {
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 30px; /* 136.364% */
    margin-top: 20px;
    margin-bottom: 0px;
    margin-bottom: 0px;

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 18px; /* 150% */
        margin-top: 15px;
    }
}

//Синий
.headListB {
  color: #003a74;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */

  @media (max-width: 650px) {
    font-size: 16px;
  }
}

.listB {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  @media (max-width: 650px) {
    row-gap: 0;
  }
  .numB {
    color: #003a74;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    margin-right: 15px;
  }

  li {
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 26.28px */

    @media (max-width: 650px) {
      font-size: 14px;
      line-height: 18px; /* 150% */
      margin-top: 15px;
    }
  }

  &.listFlex {
    display: flex;
    flex-wrap: wrap;
    height: 300px;

    @media (max-width: 650px) {
      height: 440px;
    }
  }
}
