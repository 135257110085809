.navBar {
  padding: 25px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .container {
    max-width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 650px) {
      flex-direction: row-reverse;
    }
  }

  .logo {
    width: 114px;
    height: 30px;
  }

  .leng {
    display: flex;
    column-gap: 15px;
    color: #003a74;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
