.headerCoocePrivac {
  position: relative;
  height: 452px;
  width: 100%;

  @media (max-width: 650px) {
    height: 338px;
  }

  .headBg {
    position: absolute;
    width: 100%;
    height: 200%;
    bottom: -172px;
    object-fit: cover;
    z-index: -1;

    @media (max-width: 650px) {
      bottom: -69px;
      width: 122%;
      height: 126%;
    }
  }

  .drop {
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .eclipseR {
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .cloud {
    position: absolute;
    left: -50px;
    top: -15px;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .group {
    position: absolute;
    right: 10px;
    top: -76px;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 25px;
    }

    .headImg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 650px) {
        width: 444px;
        height: 331px;
        top: 20px;
      }
    }
  }
}
