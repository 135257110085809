.sectionBlue {
  position: relative;
  padding: 50px 0 50px 0;

  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }
}
