.sectionBlue {
  position: relative;
  padding: 50px 0 50px 0;
  background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);

  z-index: 6;

  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }

  .chooseBgL {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 80%;
    z-index: -2;

    @media (max-width: 1191px) {
      display: none;
    }
  }
  .chooseBgR {
    position: absolute;
    height: 80%;
    right: 0;
    bottom: 0;
    z-index: -2;

    @media (max-width: 1191px) {
      display: none;
    }
  }

  .groupW {
    position: absolute;
    right: 50px;
    top: -40px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .cent {
    display: none;

    @media (max-width: 452px) {
      position: absolute;
      left: 0px;
      bottom: -44px;
      display: block;
      width: 100%;
    }
  }
}
