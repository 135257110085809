.contactUs {
  position: relative;
  width: 100%;
  height: 717px;
  background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);

  .container {
    padding: 85px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    max-width: 1150px;
    margin: 0 auto;

    @media (max-width: 650px) {
      padding: 32px;
    }
  }

  .contactBg {
    position: absolute;
    object-fit: contain;
    left: 0;
    bottom: 70px;
    z-index: 0;
    width: 100%;
  }


}
