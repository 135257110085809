.menuBg {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: white;
  display: none;
  box-shadow: 0px 0px 12px 0px rgba(28, 108, 189, 0.35);


  @media (max-width: 650px) {
    display: flex;
  }

  .menuBtn {
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 6px;
    background: #003a74;
    transition: all 0.2s ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 6px;
    background: #003a74;
    position: absolute;
    left: 7px;
    top: 15px;
    transition: all 0.2s ease-in-out;
  }

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 6px;
    background: #003a74;
    position: absolute;
    left: 7px;
    bottom: 15px;
    transition: all 0.2s ease-in-out;
  }

  &.open {
    .menuBtn {
      transform: scale(0);
      left: -25px;
    }

    &::after {
      top: 48%;
      transform: rotate(-45deg);
    }

    &::before {
      bottom: 48%;
      transform: rotate(45deg);
    }
  }
}
