.sectionBlue {
  position: relative;
  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }

  .img {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
}
