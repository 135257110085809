.contHead {
  position: relative;
  height: 450px;
  width: 100%;

  @media (max-width: 650px) {
    height: 348px;
  }

  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;
  }

  .contBg {
    position: absolute;
    left: 48%;
    transform: translateX(-50%);
    object-fit: cover;
    top: 0;

    @media (max-width: 650px) {
      top: 85px;
      width: 87%;
    }
  }

  .ellipseR {
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .cloudL {
    position: absolute;
    top: 50px;
    left: 0;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .gradient {
    position: absolute;
    left: 0px;
    bottom: -299px;
    z-index: -4;

    @media (max-width: 859px) {
      display: none;
    }
  }

  .gradientMob {
    position: absolute;
    left: 52%;
    transform: translateX(-50%);
    bottom: -93px;
    width: 192%;
    z-index: -4;
    display: none;

    @media (max-width: 508px) {
      display: block;
    }
  }
}
