.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 17px 38px;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: #003a74;
  box-shadow: 0px 7px 15px 0px rgba(28, 108, 189, 0.35);
  transition: all 0.2s ease-in-out;
  z-index: 1;
  &:hover {
    background: #00a1e4;
  }
}
