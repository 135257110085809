.nav {
  display: flex;
  align-items: center;
  column-gap: 45px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 747px) {
    text-align: center;
    opacity: 0;
    position: fixed;
    right: -651px;
    top: 90px;
    background: white;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    &.active {
      opacity: 1;
      right: 0;
    }
  }

  .link {
    cursor: pointer;
    position: relative;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 0.2s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      width: 46px;
      height: 5px;
      background: #000;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      background: url(../../../public/img/underline.svg);
      background-size: cover;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }

    &:hover {
      color: #003a74;
      &::before {
        opacity: 1;
      }
    }
  }

  .activeLink {
    color: #003a74;
    opacity: 1;
    &::before {
      opacity: 1;
    }
  }
}

.menuItem {
  cursor: pointer;
  position: relative;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #003a74;
  }

  &:hover .subMenu {
    display: flex;

    @media (max-width: 747px) {
      position: static;
      height:200px;
      &::before {
        display: none;
      }
    }
  }
}

.subMenu {
  transition: all .3s ease-in-out;

  @media (min-width: 747px) {
    position: absolute;
    display: none;
    flex-direction: column;
    display: none;
    top: 110%;
    left: -31px;
    background-color: #fff;
    padding: 15px;
    transition: all 0.2s ease-in-out;
  }

  width: 294px;

  @media (max-width: 747px) {

    flex-direction: column;
    margin-top: 10px;
    row-gap: 10px;
    width: 200px;
    position: static;
    overflow: hidden;
    height: 0;
    transition: all .5s ease-in-out;
  }

  &::before {
    content: '';
    background: white;
    width: 1px;
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    position: absolute;
    left: 19px;
    top: -3px;
  }

  li {
    transition: all 0.2s ease-in-out;
    color: #000;
    display: inline-block;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 222.222% */
    text-align: left;

    @media (max-width: 747px) {
      margin-left: 67px;
      width: 297px;
    }

    &:before {
      content: '•';
      margin-right: 8px;
      color: #00a1e4;
    }

    &:hover a {
      color: #003a74;
      border-bottom: 1px solid #00a1e4;
    }
  }
}
