.header {
  width: 100%;
  height: 500px;
  position: relative;

  @media (max-width: 920px) {
    height: 534px;
  }

  @media (max-width: 650px) {
    height: 587px;
  }

  .container {
    position: relative;
    max-width: 1150px;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    height: 600px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 920px) {
      align-items: center;
      padding: 150px 25px 0 25px;
      justify-content: flex-start;
    }

    @media (max-width: 920px) {
      padding: 125px 25px 0px 25px;
    }
  }

  h1 {
    color: #003a74;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.92px;
    text-transform: uppercase;
    width: 550px;

    @media (max-width: 920px) {
      font-size: 36px;
      line-height: 50px;
      width: 100%;
      text-align: center;
    }
  }
  .paragraph {
    margin-top: 25px;
    width: 650px;
    color: #003a74;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 26.28px */

    @media (max-width: 920px) {
      text-align: center;
      width: 100%;
      margin-top: 25px;
      font-size: 16px;
    }

    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .bgHeader {
    position: absolute;
    width: 100%;
    height: 800px;
    left: 0;
    bottom: -204px;
    object-fit: cover;
    z-index: -10;

    @media (max-width: 1200px) {
      height: 800px;
      bottom: -220px;
    }

    @media (max-width: 650px) {
      height: 900px;
      bottom: -250px;
    }
  }

  .cloud {
    position: absolute;
    width: 137px;
    height: 133px;
    left: -93px;
    top: 182px;
    z-index: -9;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .cloudL {
    position: absolute;
    width: 137px;
    height: 133px;
    left: -71px;
    top: -40px;
    z-index: -9;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .headerTitle {
    position: absolute;
    width: 675px;
    height: 667px;
    right: -89px;
    top: 76px;
    z-index: -5;

    @media (max-width: 920px) {
      right: 50%;
      transform: translateX(50%);
      top: 240px;
      width: 500px;
      height: 500px;
    }

    @media (max-width: 650px) {
      right: 50%;
      transform: translateX(50%);
      top: 385px;
      width: 396px;
      height: 366px;
    }
  }

  .arrowRight {
    width: 38px;
    height: 34px;
    margin-left: 51px;
  }

  .group {
    position: absolute;
    width: 153px;
    height: 176px;
    left: -250px;
    bottom: -107px;
    z-index: -9;

    @media (max-width: 920px) {
      display: none;
    }
  }

  .groupR {
    position: absolute;
    width: 139px;
    height: 165px;
    top: -69px;
    right: 0;
    z-index: -9;

    @media (max-width: 920px) {
      display: none;
    }
  }

  .eclipseR {
    position: absolute;
    width: 137px;
    height: 140px;
    top: 0px;
    right: 0px;
    z-index: -1;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .eclipseL {
    position: absolute;
    width: 137px;
    height: 140px;
    top: 413px;
    left: -14px;
    z-index: -1;
    @media (max-width: 650px) {
      display: none;
    }
  }
}
