.footer {
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);

  .container {
    padding: 40px 0 80px 0;
    max-width: 1150px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 100px;
    row-gap: 26px;

    @media (max-width: 1124px) {
      justify-content: center;
    }

    @media (max-width: 650px) {
      padding: 40px 20px 115px 40px;
      flex-direction: column;
      align-content: center;
    }

    .siteMap {
      max-width: 359px;
      margin-top: 24px;
      display: flex;
      column-gap: 66px;
      row-gap: 15px;
      flex-wrap: wrap;

      @media (max-width: 1124px) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        cursor: pointer;
        color: #fff;
        width: 142px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        border-bottom: 1px solid #00000000;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 60%;
        }
      }
    }

    .siteContacts {
      max-width: 414px;
      margin-top: 24px;
      display: flex;
      column-gap: 50px;
      row-gap: 15px;
      flex-wrap: wrap;

      @media (max-width: 1124px) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        display: flex;
        column-gap: 8px;
        align-items: center;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        border-bottom: 1px solid #00000000;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 60%;
        }
      }

      .textTerans {
        text-transform: lowercase;
      }
    }

    .logoFoot {
      @media (max-width: 650px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      p {
        max-width: 157px;
        height: 47px;
        margin-top: 28px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 120%; /* 14.4px */
      }
    }

    .titleFooter {
      position: relative;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 50px;
        height: 2px;
        border-radius: 6px;
        background: #fff;
        left: 0;
        bottom: -5px;
      }
    }
  }

  .bottomFoot {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    width: 100%;
    background: linear-gradient(180deg, #1873ce -0.77%, #053b72 100%);

    .wrap {
      max-width: 1150px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 650px) {
        flex-direction: column-reverse;
        row-gap: 15px;
      }

      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .linkList {
        display: flex;
        column-gap: 30px;
        li {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          transition: all 0.2s ease-in-out;

          @media (max-width: 650px) {
            font-size: 12px;
          }

          &:hover {
            opacity: 60%;
          }
        }
      }
    }
  }
}
