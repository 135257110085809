.map {
  text-align: center;
  padding: 0px 0 29px 0;

  @media (max-width: 650px) {
    padding: 0 0 30px 0;
  }
  .container {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }

  .mapWrap {
    width: 100%;
    max-height: 570px;
    border-radius: 25px;
    overflow: hidden;
  }

  .groupImg {
    position: absolute;
    left: -220px;
    top: 231px;
  }

  .groupImgg {
    position: absolute;
    left: 0;
    top: 761px;

    @media (max-width: 1793px) {
      top: 961px;
    }
    @media (max-width: 1355px) {
      display: none;
    }
  }

  .dataList {
    text-align-last: left;
    padding: 65px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 780px) {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 20px;
      padding: 25px 0 25px 30px;
    }

    h3 {
      color: #003a74;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.96px;

      @media (max-width: 650px) {
        font-size: 20px;
      }
    }
    .wrapInfo {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 210px;

      @media (max-width: 650px) {
        margin-top: 10px;
      }
      .circle {
        position: absolute;
        left: -5px;
        top: 14px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: circle;
        background: #e1eaf1;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 650px) {
          top: 3px;
        }
      }

      p,
      a {
        cursor: pointer;
        padding-left: 55px;
        margin-top: 15px;
        padding-left: 30px;
        color: #003a74;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */

        @media (max-width: 650px) {
          margin-top: 5px;
        }
      }

      p + p {
        margin-top: 4px;
      }
    }
  }
}
