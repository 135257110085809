.header {
  width: 100%;
  height: 717px;
  position: relative;

  @media (max-width: 768px) {
    height: 895px;
  }

  @media (max-width: 650px) {
    height: 905px;
  }

  .container {
    position: relative;
    max-width: 1150px;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    height: 720px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 768px) {
      align-items: center;
      padding-top: 150px;
    }

    @media (max-width: 650px) {
      align-items: center;
      padding-top: 150px;
    }
  }

  h1 {
    color: #003a74;
    font-size: 125px;
    font-style: normal;
    font-weight: 700;
    line-height: 150px; /* 120% */

    @media (max-width: 650px) {
      font-size: 55px;
      line-height: 50px;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    row-gap: 20px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      margin-bottom: 350px;
    }

    @media (max-width: 650px) {
      margin-bottom: 350px;
    }
    li {
      margin-left: 45px;
      position: relative;
      color: #003a74;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */

      @media (max-width: 650px) {
        font-size: 14px;
        width: 225px;
      }

      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        left: -45px;
        top: 4px;
        background: #003a74;
        width: 27px;
        height: 27px;
      }
      &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        left: -38px;
        top: 12px;
        background: url(../../../public/img/header/V.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        width: 16px;
        height: 12px;
      }
    }
  }

  .bgHeader {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    bottom: 0;
    left: 0;
    z-index: -10;

    // @media (max-width: 768px) {
    //   height: 950px;
    // }

    // @media (max-width: 650px) {
    //   height: 950px;
    // }
  }
  .cloud {
    position: absolute;
    width: 137px;
    height: 140px;
    left: -81px;
    top: 128px;
    z-index: -9;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .drop {
    position: absolute;
    width: 440px;
    height: 650px;
    left: -135px;
    top: 0px;
    z-index: -8;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .headerTitle {
    position: absolute;
    width: 794px;
    height: 819px;
    right: -183px;
    top: -29px;
    z-index: -5;

    @media (max-width: 768px) {
      right: 50%;
      transform: translateX(62%);
      top: 298px;
      width: 452px;
      height: 415px;
    }

    @media (max-width: 650px) {
      right: 50%;
      transform: translateX(62%);
      top: 318px;
      width: 396px;
      height: 366px;
    }
  }

  .arrowRight {
    width: 38px;
    height: 34px;
    margin-left: 51px;
  }

  .mt {
    margin-top: 50px;
  }

  .group {
    position: absolute;
    width: 137px;
    height: 140px;
    left: -219px;
    bottom: -58px;
    z-index: -9;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .eclipseR {
    position: absolute;
    width: 137px;
    height: 140px;
    top: 0px;
    right: 0px;
    z-index: -1;
    @media (max-width: 650px) {
      display: none;
    }
  }

  .eclipseL {
    position: absolute;
    width: 137px;
    height: 140px;
    top: 413px;
    left: -14px;
    z-index: -1;
    @media (max-width: 650px) {
      display: none;
    }
  }
}
