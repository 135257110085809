.cloud {
  position: relative;
  height: 276px;
  width: 358px;
  text-align: center;

  &:nth-child(2) {
    margin-top: 50px;
  }
  @media (max-width: 1132px) {
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .cloudBg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .cloudTop {
    height: 40%;
    position: relative;


    .headerImg {
      position: absolute;
      object-fit: cover;
      width: 54px;
      height: 54px;
      left: 50%;
      transform: translateX(-50%);
      top: 28px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
      width: 74px;
      height: 2px;
      background: white;
    }
  }

  .text {
    margin: 0 auto;
    width: 307px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 161.538% */

  

    @media (max-width: 376px) {
      font-size: 10px;
      width:  240px;
    }
  }
}
